import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./locales/en.json";
import zhTranslation from "./locales/zh.json";

i18n
  .use(initReactI18next)
  .init({
    resources: {
        en: {
          translation: enTranslation,
        },
        zh: {
          translation: zhTranslation,
        },
      },
      lng: "zh",
      fallbackLng: "zh", 
      debug: false,
      react: {
        wait: true, 
      },
  });

export default i18n;
