// config.js

const config = {
    fetchURL: 'https://onboard-api.permadao.com/',
    oauthClientID: '97f132fc-a37f-4d25-a7a9-2121fa48b973', // onboard
    
    // local test
    //fetchURL: 'http://127.0.0.1:3002',
    //oauthClientID: '77b1f6f3-e9a0-4a9f-9b81-5f8397e8e469', // my
  };
  
  export default config;
  