import React from 'react';
import { styled } from '@mui/system';

const PermadaoIconContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '200px',
    height: '45px',
    padding: '4px',
  });

const ButtonIconContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '28px',
    height: '28px',
    padding: '2px',
  });
  
export const PermadaoIcon = () => {
    return (
        <PermadaoIconContainer>
            <img src="./PermaDAO LOGO_White_H.png" alt="Permadao Icon" style={{ width: '100%', height: '100%' }}/>
        </PermadaoIconContainer>
    );
};

export const NotionIcon = () => {
    return (
        <ButtonIconContainer>
            <img src="./notion.ico" alt="Notion Icon" style={{ width: '100%', height: '100%' }}/>
        </ButtonIconContainer>
    );
};

export const DiscordIcon = () => {
    return (
        <ButtonIconContainer>
            <img src="./discord.png" alt="Discord Icon" style={{ width: '100%', height: '100%' }}/>
        </ButtonIconContainer>
    );
};

export const WeChatIcon = () => {
    return (
        <ButtonIconContainer>
            <img src="./WeChat.png" alt="WeChat Icon" style={{ width: '100%', height: '100%' }}/>
        </ButtonIconContainer>
    );
};

export const EverIDIcon = () => {
    return (
        <ButtonIconContainer>
            <img src="./everID.png" alt="everID Icon" style={{ width: '100%', height: '100%' }}/>
        </ButtonIconContainer>
    );
};


export const MetamaskIcon = () => {
    return (
        <ButtonIconContainer>
            <img src="./metamask.png" alt="Metamask Icon" style={{ width: '100%', height: '100%' }}/>
        </ButtonIconContainer>
    );
};

export const ArConnectIcon = () => {
    return (
        <ButtonIconContainer>
            <img src="./arconnect.png" alt="Arconnect Icon" style={{ width: '100%', height: '100%' }}/>
        </ButtonIconContainer>
    );
};