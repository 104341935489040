import "./App.css";
import React, { useState, useEffect } from "react";
import config from "./config";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import DrawerAppBar from "./AppBar";
import Footer from "./Footer";
import {
  NotionIcon,
  DiscordIcon,
  WeChatIcon,
  EverIDIcon,
  MetamaskIcon,
  ArConnectIcon,
} from "./Icon";
import Container from "@mui/material/Container";
import { FormLabel } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Tips from "./Tips";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Everpay from "everpay";
import ReferralTable from "./ReferralTable";
import Web3 from "web3";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

var wallet_connected = false;
var notion_connected = false;

function App() {
  const { t } = useTranslation();
  const [labelAddress, setAddress] = useState(""); //useState can not control the translation state, thus deleting the initial translation value
  const [labelNotion, setNotion] = useState(t("to_connect"));
  const [textDiscord, setTextDiscord] = useState("");
  const [textWeChat, setTextWeChat] = useState("");
  const [textBuddy, setTextBuddy] = useState("");
  const [notionAuthLink, setNotionAuthLink] = useState(
    `https://api.notion.com/v1/oauth/authorize?client_id=${config.oauthClientID}&response_type=code&owner=user`
  );
  const [wallet_type, setWalletType] = useState("EverID");
  const [isSubmitting, setIsSubmitting] = useState(false);

  let navigate = useNavigate();

  const defaultBuddies = [
    { id: 1, notion_name: '黑莓' },
    { id: 2, notion_name: 'Xiaosong' },
    { id: 3, notion_name: 'Middle' },
    { id: 4, notion_name: '橙子' },
    { id: 5, notion_name: 'Lawrence' }
  ];
  const [buddies, setBuddies] = useState(defaultBuddies);
  const notionNames = Array.from(new Set(buddies.map((option) => option.notion_name)));

  const handleAutocompleteChange = (event, newValue) => {
    setTextBuddy(newValue);
  };

  // Handler for wallet type select change
  const handleWalletTypeChange = (event) => {
    setWalletType(event.target.value);
    wallet_connected = false;
  };

  const onConnectWallet = () => {
    if (wallet_type === "EverID") {
      connectEverID();
    } else if (wallet_type === "ArConnect") {
      connectArconnect();
    } else {
      connectMetamask();
    }
  };

  const handleDiscordWeChatChange = (event) => {
    const { id, value } = event.target;

    if (id === "DiscordName") {
      setTextDiscord(value);
    } else if (id === "WeChatName") {
      setTextWeChat(value);
    }
  };

  // metamask
  const connectMetamask = async () => {
    try {
      await window.ethereum.request({
        method: "eth_requestAccounts",
        params: [],
      });
      const web3 = new Web3(window.ethereum);
      const accounts = await web3.eth.getAccounts();
      const currentAddress = accounts[0];
      setAddress(currentAddress);
      wallet_connected = true;
      console.log(labelAddress);
    } catch (error) {
      console.log(error);
    }
  };

  // arconnect
  const connectArconnect = async () => {
    if (typeof window.arweaveWallet !== "undefined") {
      try {
        await window.arweaveWallet.connect(["ACCESS_ADDRESS"]);
        // obtain the user's active wallet address
        const activeAddress = await window.arweaveWallet.getActiveAddress();
        setAddress(activeAddress);
        wallet_connected = true;
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Can not find ArConnect");
    }
  };


  //everpay
  const connectEverID = async () => {
    try {
      const authResult = await new Everpay().smartAccountAuth(
        "https://app-dev.permaswap.network/permalogo.svg"
      );
      const everpay = new Everpay({
        account: authResult.account,
        isSmartAccount: true,
      });
      setAddress(authResult.account);
      console.log(labelAddress);
      wallet_connected = true;
    } catch (error) {
      console.log(error);
    }
  };

  // notion
  const onConnectNotion = () => {
    window.location.href = notionAuthLink;
  };

  // submit
  const onSubmit = async () => {
    console.log(
      `submit buddy: ${textBuddy}`
    );
    const dc_WeChat_fieldCheck = !textDiscord.trim() && !textWeChat.trim();
    if (
      wallet_connected &&
      notion_connected &&
      !dc_WeChat_fieldCheck &&
      textBuddy.trim()
    ) {
      setIsSubmitting(true); // Disable the submit button
      console.log(
        `submit notioncode: ${labelNotion}, wallet: ${labelAddress}, discord: ${textDiscord}, buddy: ${textBuddy}`
      );
      fetch(config.fetchURL + "/v1/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          notioncode: labelNotion,
          wallet: labelAddress,
          discord: textDiscord,
          buddy: textBuddy,
          wechat: textWeChat,
        }),
      }).then(async (resp) => {
        if (resp.ok) {
          setIsSubmitting(false); // Enable the submit button
          notion_connected = false;
          navigate("/SubmitSuccess");
        } else {
          setIsSubmitting(false); // Enable the submit button
          notion_connected = false;
          alert("Error occurred while submitting.");
        }
      });
    } else {
      if (
        !notion_connected ||
        !wallet_connected ||
        dc_WeChat_fieldCheck ||
        !textBuddy.trim()
      ) {
        alert(t("submit_check_alert"));
      }
    }
  };

  // get all contributors
  async function fetchContributors() {
    try {
      const response = await fetch(config.fetchURL + '/v1/contributors');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      
      if (jsonData.code === 200) {
        const updatedBuddies = [...defaultBuddies, ...jsonData.contributors];
        
        setBuddies(updatedBuddies);
        console.log('Contributors fetched:', jsonData.contributors);
      } else {
        console.error('Failed to fetch contributors:', jsonData.message);
      }
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    }
  }
  // etchContributors
  useEffect(() => {
    fetchContributors();
  }, []);


  useEffect(() => {
    const params = new URL(window.document.location).searchParams;
    const code = params.get("code");
    if (!code) {
      setNotion(t("to_connect"));
      notion_connected = false;
      return;
    }
    console.log(`code: ${code}`);
    setNotion(code);
    notion_connected = true;
  });

  return (
    <div>
      <DrawerAppBar position="static"></DrawerAppBar>
      <div className="content">
        <Container component="main" sx={{ mt: 0, mb: 2 }}>
          <Typography
            component="h1"
            variant="h3"
            align="center"
            fontWeight="bold"
            color="green"
            gutterBottom
          >
            Onboard PermaDAO
          </Typography>
        </Container>
        <p></p>
        <div className="container">
          <div className="center">
            <div className="connect-container">
              <Button
                variant="outlined"
                color="success"
                sx={{ width: "208px" }}
                onClick={onConnectNotion}
                startIcon={<NotionIcon />}
                style={{ textTransform: "none" }}
              >
                {t("connnect_notion")}
              </Button>
              {!notion_connected && <CancelSharpIcon color="action" />}
              {notion_connected && <CheckCircleSharpIcon color="success" />}
            </div>
            <p></p>
            <div className="connect-container">
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="wallet-select">{t('wallet_type')}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={wallet_type}
                  label={t('wallet_type')}
                  onChange={handleWalletTypeChange}
                >
                  <MenuItem value="EverID">EverID</MenuItem>
                  <MenuItem value="MetaMask">MetaMask</MenuItem>
                  <MenuItem value="ArConnect">ArConnect</MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="outlined"
                color="success"
                sx={{ width: "208px" }}
                onClick={onConnectWallet}
                startIcon={
                  wallet_type === "EverID" ? <EverIDIcon /> :
                    wallet_type === "ArConnect" ? <ArConnectIcon /> :
                      <MetamaskIcon />
                }
                style={{ textTransform: "none" }}
              >
                {wallet_type === "EverID"
                  ? t("connect_everID")
                  : wallet_type === "ArConnect"
                    ? t("connect_ArConnect")
                    : t("connect_MetaMask")}
              </Button>
              {!wallet_connected && <CancelSharpIcon color="action" />}
              {wallet_connected && <CheckCircleSharpIcon color="success" />}
            </div>
            <div className="space"></div>
            <Typography variant="body2" component="span" className="small-text">
              {t("everID_tip")}
              <a
                target="_blank"
                href="https://www.notion.so/EverID-Web3-ef6ef4f07c0341a0a096511b56ae29ab?pvs=24"
              >
                {t("everID_link")}
              </a>
            </Typography>
            <p></p>
            <div className="connect-container">
              <TextField
                id="DiscordName"
                label={t("discord_name")}
                size="small"
                color="success"
                sx={{ width: "235px" }}
                value={textDiscord}
                onChange={handleDiscordWeChatChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DiscordIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                focused
              />

              <FormLabel
                className="textFieldLabel"
                sx={{
                  width: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "20px 0"
                }}
              >
                {t("or")}
              </FormLabel>

              <div class="textFieldSpace"></div>
              <TextField
                id="WeChatName"
                label={t("weChat_name")}
                size="small"
                color="success"
                sx={{ width: "235px" }}
                value={textWeChat}
                onChange={handleDiscordWeChatChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <WeChatIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                focused
              />
            </div>
            <div className="space"></div>
            <Typography variant="body2" component="span" className="small-text">
              {t("dc_tip")}
            </Typography>
            <p></p>
            <div>
              <Stack spacing={2} sx={{ width: 235 }}>
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  value={textBuddy}
                  onChange={handleAutocompleteChange}
                  options={notionNames}
                  renderInput={(params) => <TextField {...params} label={t("buddy")} />}
                />
              </Stack>
              <FormHelperText sx={{ width: "205px" }}>{t("buddy_ex")}</FormHelperText>
            </div>
            <p></p>
            <Button
              variant="contained"
              color="success"
              onClick={onSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? t("submitting") : t("submit")}
            </Button>
            <p></p>
            <div className="OnboardReferralBox">
              <div className="onboardingVideo">
                <p className="videoLink">
                  {t("onboarding_tutorial_title")}
                  <a target="_blank" href="https://youtu.be/uSwliA80QiE">
                    https://youtu.be/uSwliA80QiE
                  </a>
                </p>
                <div class="space"></div>
                <div class="space"></div>
                <div class="space"></div>
                <iframe
                  width={520}
                  height={320}
                  src="https://www.youtube.com/embed/uSwliA80QiE?si=kzQDR6Nyp9jqxvvu"
                  title="onBoarding Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen=""
                />
              </div>
              <div class="textFieldSpace"></div>
              <div class="textFieldSpace"></div>
              <div class="textFieldSpace"></div>
              <div className="referral" style={{ width: "80%", height: "70%" }}>
                {t("referralTable_title")}
                <div class="space"></div>
                <div class="space"></div>
                <div class="space"></div>
                <ReferralTable />
              </div>
            </div>
            <p></p>
            <Tips />
            <p></p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
