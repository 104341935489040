import React from 'react';
import './App.css'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";

const Tips = () => {
  const { t } = useTranslation();

  return (
    <Box 
      bgcolor="#f5f5f5" 
      borderRadius={3}
      p={2}
      maxWidth="md"
    >
      <Typography variant="head5" color='green'>
        {t('onboard_tip_title')}
      </Typography>
      <Typography variant="body2" color="text.secondary"  paragraph >
        {t("onboard_tip_text")}
      </Typography>
      <Typography variant="head5" color='green'  >
        {t("safe_tip_title")}
      </Typography>
      <Typography variant="body2"  color="text.secondary" >
        {t("safe_tip_text")}
      </Typography>
      <Typography variant="body2" color="text.secondary" >
        {t("onboard_link")} 
      </Typography>
    </Box>
  );
};

export default Tips;
