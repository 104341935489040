import { useTranslation } from "react-i18next";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function ReferralTable() {
  const { t } = useTranslation();

  function createData(default_referral, weixin_id, discord_id, greeting_note) {
    return { default_referral, weixin_id, discord_id, greeting_note };
  }

  const rows = [
    createData("黑莓", "Sctb_photographer", "heise4430", "PermaDAO"),
    createData("Xiaosong", "effortzerg", "song.hu", "PermaDAO"),
    createData("Middle", "xuezhijie156", "/", "PermaDAO"),
    createData("橙子", "ccfc2001", "baobaobrc", "PermaDAO"),
    createData("Lawrence", "liu253198034", "liu253198034", "PermaDAO"),
  ];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead style={{backgroundColor:"#f5f5f5"}} align="left">
          <TableRow>
            <TableCell align="left">{t("recommendTable_default_referral")}</TableCell>
            <TableCell align="left">
              {t("recommendTable_weixin_id")}
            </TableCell>
            <TableCell align="left">
              {t("recommendTable_discord_id")}
            </TableCell>
            <TableCell align="left">
              {t("recommendTable_greeting_note")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.default_referral}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="left">
                {row.default_referral}
              </TableCell>
              <TableCell align="left">{row.weixin_id}</TableCell>
              <TableCell align="left">{row.discord_id}</TableCell>
              <TableCell align="left">{row.greeting_note}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
