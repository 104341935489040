import React from 'react';
import './App.css'
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const Footer = () => {
  return (
    <footer>
      <p className="footer">
        <Typography variant="body2" color="text.secondary">
          {'Copyright © '}
        <Link color="inherit" href="https://permadao.com">
          Permadao
        </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </p>
      
    </footer>
  );
};

export default Footer;
