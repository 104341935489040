import DrawerAppBar from "./AppBar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import * as React from "react";
import ReferralTable from "./ReferralTable";
import Tips from "./Tips";

function SubmitSuccessPage() {
  const { t } = useTranslation();

  return (
    <div>
      <DrawerAppBar position="static"></DrawerAppBar>
      <Container component="main" sx={{ mt: 0, mb: 2 }}>
        <div className="OnboardReferralBox">
          <div className="onboardingVideo">
            <p>{t("submit_success_content_line1")}</p>
            <p>{t("submit_success_content_line2")}</p>
            <p>{t("submit_success_content_line3")}</p>
            <div className="referLinks">
              <Link href="https://discord.gg/y6CmaAdVD8">
                {t("dc_official_link")}
              </Link>
              <div className="space"></div>
              <Link href="https://t.me/PermaDao_ar">Telegram</Link>
              <div className="space"></div>
              <Link href="https://twitter.com/perma_daoCN">
                {t("x_official_link")}
              </Link>
            </div>
          </div>
          <div class="textFieldSpace"></div>
          <div className="referral">
            <ReferralTable />
          </div>
        </div>
        <div className="bigSpace"></div>   
        <div className="TipBox">
        <Tips />
        </div>           
      </Container> 
      <div className="bigSpace"></div>
      <Footer />
    </div>
  );
}

export default SubmitSuccessPage;
